import React, { useContext, useEffect, useState } from "react";
import banner1 from "./../assets/png/slider/Banner1.png";
import banner1Mobile from "./../assets/png/slider/Banner1Mobile.png"; // Mobile image for Banner1
import banner2 from "./../assets/png/slider/Banner2.jpg";
import banner2Mobile from "./../assets/png/slider/Banner2Mobile.png"; // Mobile image for Banner2
import banner3 from "./../assets/png/slider/Banner3.jpg";
import banner3Mobile from "./../assets/png/slider/Banner3Mobile.png"; // Mobile image for Banner3
import banner4 from "./../assets/png/slider/Banner4.jpg";
import banner4Mobile from "./../assets/png/slider/Banner4Mobile.png"; // Mobile image for Banner4
import banner5 from "./../assets/png/slider/Banner5.jpg";
import banner5Mobile from "./../assets/png/slider/Banner5Mobile.png"; // Mobile image for Banner5
import banner6 from "./../assets/png/slider/Banner6.jpg";
import banner6Mobile from "./../assets/png/slider/Banner6Mobile.png"; // Mobile image for Banner6
import page from "./page.module.scss";
import about from "./../assets/png/about.png";
import { GrAdd, GrClose } from "react-icons/gr";
import Tabs from "../templates/Tabs";
import Testimonial from "../templates/Testimonial";
import ocean from "../assets/png/oceanFreight.svg";
import rail from "../assets/png/railFreight.svg";
import land from "../assets/png/landFreight.svg";
import air from "../assets/png/airFreight.svg";
import secure from "../assets/png/secure.png";
import HappyClients from "../templates/HappyClients";
import logo from "../assets/png/NGL-LOGO-300X300PIXELS 1.png";
import { motion } from "framer-motion";
import { cursorContext } from "../cursorContext";
import greeting from "./../assets/png/greeting.jpeg";

const Home = () => {
  let slideInterval;
  const autoScroll = true;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect mobile on load

  // Function to handle screen resizing
  const updateScreenSize = () => {
    setIsMobile(window.innerWidth <= 768); // Update state based on screen width
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize); // Listen for resize events
    return () => window.removeEventListener("resize", updateScreenSize); // Clean up event listener
  }, []);

  const slides = [
    {
      slideName: "Transportation",
      slideImg: banner1,
      slideImgMobile: banner1Mobile,
      render: () => {
        return (
          <div className={page.sliderIn}>
            <div className={page.logo}>
              <img className={page.mobviewlogo} src={logo} alt="logo" draggable={false} />
            </div>
            <div className={page.content}>
              <strong className={page.heading}>
                Your Trusted Partner in Seamless Logistics Solutions
              </strong>
              <p className={page.subHeading}>
                No matter the location we got you covered with our global
                shipping!
              </p>
            </div>
          </div>
        );
      },
    },
    {
      slideName: "Sea Freight",
      slideImg: banner2,
      slideImgMobile: banner2Mobile,
      render: () => {
        return (
          <div className={page.sliderIn}>
            <div className={page.logo}>
              <img className={page.mobviewlogo} src={logo} alt="logo" draggable={false} />
            </div>
            <div className={page.content}>
              <strong className={page.heading}>
                Navigating Waves, Delivering Promises Worldwide.
              </strong>
              <p className={page.subHeading}>
                Reliable sea freight solutions by Nekoda. Secure, timely, and cost-effective transport for your global shipments.
              </p>
            </div>
          </div>
        );
      },
    },
    {
      slideName: "Air Freight",
      slideImg: banner3,
      slideImgMobile: banner3Mobile,
      render: () => {
        return (
          <div className={page.sliderIn}>
            <div className={page.logo}>
              <img className={page.mobviewlogo} src={logo} alt="logo" draggable={false} />
            </div>
            <div className={page.content}>
              <strong className={page.heading}>
                Rapid Routes, Secure Transits: Unmatched Air Freight Solutions.
              </strong>
              <p className={page.subHeading}>
                 Elevate your logistics with our air freight solutions
              </p>
            </div>
          </div>
        );
      },
    },
    {
      slideName: "Rail Freight",
      slideImg: banner4,
      slideImgMobile: banner4Mobile,
      render: () => {
        return (
          <div className={page.sliderIn}>
            <div className={page.logo}>
              <img className={page.mobviewlogo} src={logo} alt="logo" draggable={false} />
            </div>
            <div className={page.content}>
              <strong className={page.heading}>
                 Beyond Borders, On the Rails: A Journey of Dependability.
              </strong>
              <p className={page.subHeading}>
                 Secure rail freight solutions for swift and dependable cargo transport.
              </p>
            </div>
          </div>
        );
      },
    },
    {
      slideName: "Warehousing",
      slideImg: banner5,
      slideImgMobile: banner5Mobile,
      render: () => {
        return (
          <div className={page.sliderIn}>
            <div className={page.logo}>
              <img className={page.mobviewlogo} src={logo} alt="logo" draggable={false} />
            </div>
            <div className={page.content}>
              <strong className={page.heading}>
                Warehousing Excellence: Where Security Meets Efficiency
              </strong>
              <p className={page.subHeading}>
                Strategic warehousing solutions: Secure, accessible, and tailored for your storage needs
              </p>
            </div>
          </div>
        );
      },
    },
    {
      slideName: "Customers Clearance",
      slideImg: banner6,
      slideImgMobile: banner6Mobile,
      render: () => {
        return (
          <div className={page.sliderIn}>
            <div className={page.logo}>
              <img className={page.mobviewlogo} src={logo} alt="logo" draggable={false} />
            </div>
            <div className={page.content}>
              <strong className={page.heading}>
                 Simplifying Regulations, Elevating Your Import-Export Journey
              </strong>
              <p className={page.subHeading}>
                Efficient customs solutions: Navigating regulations, ensuring smooth global transactions for you
              </p>
            </div>
          </div>
        );
      },
    },
  ];

  // const [currentIndex, setCurrentIndex] = useState(0);
  const [showGreeting, setShowGreeting] = useState(false);

  const handleClick = (e, index) => {
    e.preventDefault();

    setCurrentIndex(index);
  };

  const nextSlide = () => {
    setCurrentIndex(currentIndex === slides.length - 1 ? 0 : currentIndex + 1);
  };

  const previousSlide = () => {
    setCurrentIndex(currentIndex === 0 ? slides.length - 1 : currentIndex - 1);
  };

  function autoSlide() {
    slideInterval = setInterval(nextSlide, 3000);
  }

  const imageAnimation = {
    hidden: {
      clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
    },
    show: {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      transition: {
        delay: 0,
        duration: 0,
        ease: "easeInOut",
      },
    },
  };

  useEffect(() => {
    if (autoScroll) {
      autoSlide();
    }

    return () => clearInterval(slideInterval);
  }, [currentIndex]);

  useEffect(() => {
    const hasShownGreeting = sessionStorage.getItem("greeting");
    console.log(hasShownGreeting);

    if (!hasShownGreeting) {
      setShowGreeting(true);
      sessionStorage.setItem("greeting", "true");
    }

    window.addEventListener("beforeunload", () => {
      sessionStorage.removeItem("greeting");
    });
  }, []);

  const closeGreeting = () => {
    setShowGreeting(false);
  };

  const { mouseLeaveHandler, mouseButtonHandler } = useContext(cursorContext);

  return (
    <>
      {showGreeting && (
        <div className={page.greetingPopup}>
          <div className={page.greetingBanner}>
            <div className={page.imageContainer}>
              <img src={greeting} draggable={false} alt="greetings"/>
              <button
                className={page.greetingClose}
                onClick={() => closeGreeting()}>
                <GrClose />
              </button>
            </div>
           
          </div>
         
        </div>
      )}
      <div className={page.slider}>
        <div className={page.list}>
          {slides.map((slide, index) => (
            <div
              className={
                index === currentIndex
                  ? `${page.item} ${page.currentItem}`
                  : `${page.item}`
              }
              key={index}>
              {index === currentIndex && (
                <motion.img
                  key={slide.slideName || "empty"}
                  src={isMobile ? slide.slideImgMobile : slide.slideImg} // Use isMobile here
                  variants={imageAnimation}
                  initial="hidden"
                  animate="show"></motion.img>
              )}
            </div>
          ))}
        </div>
        <div className={page.thumbnail}>
          <div className={page.group}>
            {slides.map((slide, index) => (
              <motion.div
                onMouseEnter={mouseButtonHandler}
                onMouseLeave={mouseLeaveHandler}
                className={
                  index === currentIndex
                    ? `${page.thumbnailItem} ${page.activeThumbnail}`
                    : `${page.thumbnailItem}`
                }
                key={index}
                onClick={(e) => handleClick(e, index)}>
                {slide.slideName}
              </motion.div>
            ))}
          </div>
        </div>
        <div
          className={
            slides[currentIndex]
              ? `${page.sliderContent} ${page.active}`
              : `${page.sliderContent}`
          }>
          {slides && slides[currentIndex]?.render()}
        </div>
        <div
          className={`${page.arrow} ${page.leftArrow}`}
          onClick={() => previousSlide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.456"
            height="48.083"
            viewBox="0 0 25.456 48.083">
            <rect
              width="100%"
              height="100%"
              transform="translate(1.414 22.627) rotate(45)"
              className={page.rect}></rect>
            <rect
              width="100%"
              height="100%"
              transform="translate(0 24.042) rotate(-45)"
              className={page.rect}></rect>
            <rect
              className={page.rectHorizontal}
              transform="translate(2.433 23.042)"></rect>
          </svg>
        </div>
        <div
          className={`${page.arrow} ${page.rightArrow}`}
          onClick={() => nextSlide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.456"
            height="48.083"
            viewBox="0 0 25.456 48.083">
            <rect
              width="100%"
              height="100%"
              transform="translate(1.414 22.627) rotate(45)"
              className={page.rect}></rect>
            <rect
              width="100%"
              height="100%"
              transform="translate(0 24.042) rotate(-45)"
              className={page.rect}></rect>
            <rect
              className={page.rectHorizontal}
              transform="translate(2.433 23.042)"></rect>
          </svg>
        </div>
      </div>
      <div className={`${page.banner} ${page.bg_grey}`}>
        <div className={`${page.container}`}>
          <div
            className={`${page.flex} ${page.content}  ${page.h_full} ${page.alignItemsCenter}`}>
            <div className={`${page.flexOne}`}>
              <strong className={page.bold_text}>
                Nekoda Logistics, stands as your trusted partner for seamless
                freight and project handling
              </strong>
              <span className={page.small_text_16}>
                Your logistics challenges find expert solutions at Nekoda –
                where efficiency meets reliability.
              </span>
              {/*/ 
              <div className={`${page.view_more}`}>
                <motion.div
                  onMouseEnter={mouseButtonHandler}
                  onMouseLeave={mouseLeaveHandler}
                  className={page.circle}>
                  <GrAdd />
                </motion.div>
                <span className={page.value}>View more</span>
              </div>*/}
            </div>
            <div
              className={`${page.border_left}  ${page.flexOne} ${page.pl_3rem}`}>
              <img src={about} alt="banner" draggable={false} />
            </div>
          </div>
        </div>
      </div>
      <Tabs />
      <Testimonial />
      <div className={page.freight}>
        <div className={page.container}>
          <div className={page.group}>
            <div className={page.item}>
              <div className={page.itemImage}>
                <img src={ocean} alt="Ocean Freight" draggable={false} />
              </div>
              <label className={page.label}>Ocean Freight</label>
              <p className={page.text}>
                Global sea freight expertise – secure, timely, and
                cost-effective cargo solutions with Nekoda
              </p>
            </div>
            <div className={page.item}>
              <div className={page.itemImage}>
                <img src={rail} alt="Rail Freight" draggable={false} />
              </div>
              <label className={page.label}>Rail Freight</label>
              <p className={page.text}>
                Sustainable, efficient, and cost-effective freight
                transportation for your cargo.
              </p>
            </div>
            <div className={page.item}>
              <div className={page.itemImage}>
                <img src={land} alt="Land Freight" draggable={false} />
              </div>
              <label className={page.label}>Land Freight</label>
              <p className={page.text}>
                Dependable land freight by Nekoda– timely, secure, and flexible
                transport for your valuable cargo.
              </p>
            </div>
            <div className={page.item}>
              <div className={page.itemImage}>
                <img src={air} alt="Air Freight" draggable={false} />
              </div>
              <label className={page.label}>Air Freight</label>
              <p className={page.text}>
                Swift and reliable air freight solutions by Nekoda – ensuring
                your cargo reaches its destination seamlessly.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${page.banner}`}>
        <div className={`${page.container}`}>
          <div
            className={`${page.flex} ${page.content} ${page.h_full} ${page.alignItemsCenter}`}>
            <div className={`${page.flexOne}`}>
              <strong className={page.bold_text}>
                Secure courier for all <br /> your packages
              </strong>
              <span className={page.small_text_16}>
                Choose peace of mind with our secure courier service, ensuring
                the safety of all your packages. At Nekoda, we prioritize the
                security and timely delivery of your shipments. Trust us to
                handle your valuable items with the utmost care and reliability,
                providing a secure and dependable courier solution
              </span>
                {/* 
              <div className={`${page.view_more}`}>
                <motion.div
                  onMouseEnter={mouseButtonHandler}
                  onMouseLeave={mouseLeaveHandler}
                  className={page.circle}>
                  <GrAdd />
                </motion.div>
                <span className={page.value}>View more</span>
              </div>*/}
            </div>
            <div className={`${page.border_left} ${page.pl_3rem}`}>
              <img src={secure} alt="banner" draggable={false} />
            </div>
          </div>
        </div>
      </div>
      
      <div className={page.parallax}>
        <div className={page.container}>
          <div className={page.group}>
            <strong className={page.heading}>
              Reliable Deliveries,
              <br /> Unmatched Security: <br /> Your Trusted Courier Partner.
            </strong>
            <p className={page.subHeading}>
              We offers a spectrum of services including Sea and Air Freight,
              Customs <br /> Clearance, Warehousing, Transportation, Rail
              Freight, and expert Project <br /> Handling
            </p>
          </div>
        </div>
      </div>
      <div className={page.innovative}>
        <div className={page.container}>
          <div className={page.innoText}>
            An innovative service for easy & fast <br /> shipments,
            transportation coordinated by <br /> qualified staff
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
