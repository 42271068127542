import React, { useContext, useState } from "react";
import styles from "./Template.module.scss";
import { motion } from "framer-motion";
import sea from "./../assets/png/sea.png";
import air from "./../assets/png/air.jpg";
import rail from "./../assets/png/rail.jpg";
import transportation from "./../assets/png/transportation.jpg";
import warehouse from "./../assets/png/warehouse.jpg";
import customs from "./../assets/png/customs.jpg";
import { GrAdd } from "react-icons/gr";
import { cursorContext } from "../cursorContext";

const Tabs = () => {
  const { mouseButtonHandler, mouseLeaveHandler } = useContext(cursorContext);
  const tabs = [
    {
      name: "Sea Freight",
      label: "Sea Freight",
      image: sea,
      render: () => {
        return (
          <div className={styles.content}>
            <strong className={styles.big_text_44}>
              Seamless Ocean Solutions <br /> with Nekoda Logistics.
            </strong>
            <p className={styles.small_text_16}>
              Trust Nekoda Logistics for reliable sea freight services,
              delivering cost-effective and secure transportation solutions. Our
              expert team ensures smooth sailing for your cargo, providing
              unmatched efficiency and peace of mind.
            </p>
          </div>
        );
      },
    },
    {
      name: "Air Freight",
      label: "Air Freight",
      image: air,
      render: () => {
        return (
          <div className={styles.content}>
            <strong className={styles.big_text_44}>
                Swift Air Solutions,<br /> 
                Delivered by Nekoda. 
            </strong>
            <p className={styles.small_text_16}>
                Nekoda Logistics offers swift and efficient air freight services, meeting tight delivery schedules with precision. Experience the speed and reliability of our air transportation solutions, ensuring your cargo reaches its destination on time, every time.
            </p>
          </div>
        );
      },
    },
    {
      name: "Rail Freight",
      label: "Rail Freight",
      image: rail,
      render: () => {
        return (
          <div className={styles.content}>
            <strong className={styles.big_text_44}>
              Green and Efficient<br /> 
              Rail Solutions by Nekoda
            </strong>
            <p className={styles.small_text_16}>
              Discover the eco-friendly advantages of rail freight with Nekoda Logistics. Our extensive rail network ensures cost-effective and efficient transportation, contributing to sustainable logistics solutions for your cargo.
            </p>
          </div>
        );
      },
    },
    {
      name: "Transportation",
      label: "Transportation",
      image: transportation,
      render: () => {
        return (
          <div className={styles.content}>
            <strong className={styles.big_text_44}>
              On the Move with<br /> Nekoda Logistics
            </strong>
            <p className={styles.small_text_16}>
              Depend on Nekoda Logistics for reliable ground transportation solutions. Our diverse fleet guarantees the safe and timely transport of your goods, providing flexibility and efficiency in meeting your logistics needs.
            </p>
          </div>
        );
      },
    },
    {
      name: "Warehousing",
      label: "Warehousing",
      image: warehouse,
      render: () => {
        return (
          <div className={styles.content}>
            <strong className={styles.big_text_44}>
               Secure Storage,<br /> Tailored by Nekoda.
            </strong>
            <p className={styles.small_text_16}>
               Explore Nekoda Logistics' warehousing solutions – be it FTWZ, Bonded, or Private. Our state-of-the-art facilities boast advanced security, cutting-edge technology, and efficient management systems, ensuring your goods are stored securely and professionally.
            </p>
          </div>
        );
      },
    },
    {
      name: "Customs Clearance",
      label: "Customs Clearance",
      image: customs,
      render: () => {
        return (
          <div className={styles.content}>
            <strong className={styles.big_text_44}>
               Customs Hassles,<br />
               Simplified by Nekoda.
            </strong>
            <p className={styles.small_text_16}>
               Let Nekoda Logistics be your guide through customs complexities. Our experienced team ensures seamless customs clearance, navigating regulations with expertise. Trust us to streamline the process, providing hassle-free clearance for your shipments.
            </p>
          </div>
        );
      },
    },
  ];

  const imageAnimation = {
    hidden: {
      clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
    },
    show: {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      transition: {
        delay: 0.3,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (e, tab) => {
    e.preventDefault();

    setActiveTab(tab);
  };

  const isSelected = (tab) => activeTab.name === tab.name;

  return (
    <div className={styles.tab}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.tabHeader}>
            {tabs.map((tab, index) => (
              <motion.label
                onMouseEnter={mouseButtonHandler}
                onMouseLeave={mouseLeaveHandler}
                className={`${styles.tabLabel} ${
                  isSelected(tab) ? `${styles.active}` : ""
                }`}
                key={tab.name}
                onClick={(e) => handleClick(e, tab)}>
                {tab.label}
              </motion.label>
            ))}
          </div>
          <div className={styles.tabContent}>
            <div className={styles.tabFlex}>
              <div className={styles.tabImage}>
                <motion.img
                  key={activeTab.name || "empty"}
                  src={activeTab.image}
                  className={styles.img}
                  variants={imageAnimation}
                  initial="hidden"
                  animate="show"></motion.img>
              </div>
              <div className={styles.tabTextContent}>
                {activeTab && activeTab?.render()}
                {/*
                <div className={`${styles.view_more}`}>
                  <motion.div
                    onMouseEnter={mouseButtonHandler}
                    onMouseLeave={mouseLeaveHandler}
                    className={styles.circle}>
                    <GrAdd />
                  </motion.div>
                  <span className={styles.value}>View more</span>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
