import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import emailjs
import careerBanner from "../assets/png/careerBanner.png";
import joinOurTeam from "../assets/png/joinOurTeam.png";
import styles from "./page.module.scss";
import { GrAdd } from "react-icons/gr";

const Career = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const careers = [
    {
      position: "Stocking Associate",
      location: "Chennai",
      type: "Full Time",
    },
    {
      position: "Stocking Associate",
      location: "Chennai",
      type: "Full Time",
    },
    {
      position: "Stocking Associate",
      location: "Chennai",
      type: "Full Time",
    },
    {
      position: "Stocking Associate",
      location: "Chennai",
      type: "Full Time",
    },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_xic5lp4", // Your Service ID
        "template_8589mmy", // Your Template ID
        formData, // Data to send (name, email, message)
        "iM6pwZjf9TXn3UkUX" // Your Public Key
      )
      .then((response) => {
        alert("Application submitted successfully!");
        setFormData({ name: "", email: "", message: "" }); // Reset form after success
      })
      .catch((error) => {
        console.error("Failed to send application:", error);
        alert("Failed to send application. Please try again.");
      });
  };

  return (
    <>
      <div className={styles.bannerOne}>
        <div className={styles.Image}>
          <img src={careerBanner} alt="About" draggable={false} />
          <strong className={styles.screenName}>Career</strong>
        </div>
      </div>

      <div className={`${styles.banner}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.flex} ${styles.content} ${styles.h_full} ${styles.alignItemsCenter}`}>
            <div className={`${styles.flexOne}`}>
              <strong className={styles.bold_text}>
                Join Our Team, Shape <br /> the Future.
              </strong>
              <span className={styles.small_text_16}>
                Embark on a rewarding career with [Your Company Name]. We value innovation, teamwork, and growth. Explore exciting opportunities and contribute to our dynamic and thriving logistics family. Your journey begins here.
              </span>
            </div>
            <div className={`${styles.border_left} ${styles.flexOne} ${styles.pl_3rem}`}>
              <img src={joinOurTeam} alt="banner" draggable={false} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.formBanner}>
        <div className={styles.container}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <h1 className={styles.formHeading}>Become a part of our team</h1>
            <div className={styles.formGroup}>
              <input
                type="text"
                name="name"
                className={styles.formControl}
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="email"
                name="email"
                className={styles.formControl}
                placeholder="Your eMail"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <textarea
                name="message"
                placeholder="Message"
                className={styles.formControl}
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <button className={styles.button} type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Career;
